/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from "react";

declare global {
  interface Window {
    gtag: (
      option: string,
      gaTrackingId: string,
      options: Record<string, unknown>,
    ) => void;
  }
}

const GoogleAnalytics = ({ gaTrackingId }: { gaTrackingId: string | null }) => (
  <Fragment>
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId ?? ""}`}
    />
    <script
      async
      id="gtag-init"
      dangerouslySetInnerHTML={{
        __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gaTrackingId ?? ""}', {
        page_path: window.location.pathname,
      });
    `,
      }}
    />
  </Fragment>
);

const sendGAEvent = ({
  name,
  properties,
}:
  | {
      name: "search";
      properties: {
        user_company: string;
        user_email: string;
        date: string;
        city: string;
        state: string;
        search_term: string;
      };
    }
  | {
      name: "report";
      properties: {
        user_company: string;
        user_email: string;
        date: string;
        type: string;
        dot: string;
        mc: string;
      };
    }
  | {
      name: "review";
      properties: {
        user_company: string;
        user_email: string;
        date: string;
        rating: string;
        dot: string;
        mc: string;
      };
    }
  | {
      name: "tracking";
      properties: {
        user_company: string;
        user_email: string;
        date: string;
        percentage: string;
        dot: string;
        mc: string;
      };
    }
  | {
      name: "mfa";
      properties: {
        user_email: string;
      };
    }
  | {
      name: "advanced-search";
      properties: {
        user_company: string;
        user_email: string;
        date: string;
        search: string;
      };
    }): void => {
  if (process.env.NODE_ENV === "production" && window.gtag) {
    window.gtag("event", name, properties);
  }
};

const identifyGAUser = (userId: string): void => {
  if (
    process.env.NODE_ENV === "production" &&
    window.ENV.GA_TRACKING_ID &&
    window.gtag
  ) {
    window.gtag("config", window.ENV.GA_TRACKING_ID, {
      user_id: userId,
    });
  }
};

const viewGAPage = (url: string, trackingId: string) => {
  if (!window.gtag) {
    // eslint-disable-next-line no-console
    return console.warn(
      "window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.",
    );
  }
  window.gtag("config", trackingId, {
    page_path: url,
  });
};

export { GoogleAnalytics, sendGAEvent, identifyGAUser, viewGAPage };
